import * as React from "react";
import logo from "../assets/logo.png";

export default function Footer() {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <footer className="bg-blue-50 dark:bg-gray-900">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="flex justify-center mb-6 md:mb-0 md:justify-start">
            <a href="/" className="flex items-center">
              <img
                src={logo}
                className="h-16"
                alt="Angel Haven Logo"
              />
            </a>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Links
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-1">
                  <a href="/" className="hover:underline">
                    Home
                  </a>
                </li>
                <li className="mb-1">
                  <a href="/about" className="hover:underline">
                    About
                  </a>
                </li>
                <li className="mb-1">
                  <a href="/services" className="hover:underline">
                    Services
                  </a>
                </li>
                <li className="mb-1">
                  <a href="/amenities" className="hover:underline">
                    Amenities
                  </a>
                </li>
                <li className="mb-1">
                  <a href="/cost" className="hover:underline">
                    Costs
                  </a>
                </li>
                <li className="mb-1">
                  <a href="/contact" className="hover:underline">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Legal
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <button
                    onClick={toggleModal}
                    className="hover:underline cursor-pointer"
                    data-modal-show="privacy-modal"
                  >
                    Privacy Policy
                  </button>
                  {isModalVisible && (
                    <div id='privacy-modal' className="fixed inset-0 flex items-center justify-center">
                      <div className="absolute inset-0 bg-gray-900 bg-opacity-75" />
                      <div className="relative z-50 bg-white rounded-lg">
                        <div className="max-w-xl text-xs mx-auto p-6">
                          <h2 className="text-2xl font-bold mb-4">
                            Privacy Policy
                          </h2>
                          <p className="mb-4">
                            This Privacy Policy describes how Angel Haven Estates ("we," "us," "our") collects, uses, and discloses information when you visit our website [www.angelhavenestates.com] (the "Site").
                          </p>

                          <h2 className="text-xl font-bold mb-2">
                            Information We Collect
                          </h2>
                          <ul className="list-disc pl-6 mb-4">
                            <li>
                              <strong>Personal Information</strong>: When you visit our Site, we may collect certain personally identifiable information, such as your name, email address, phone number, and other contact information that you voluntarily provide to us through contact forms or other means.
                            </li>
                            <li>
                              <strong>Usage Information</strong>: We may also collect information about your device and how you interact with our Site, such as your IP address, browser type, pages visited, and other usage data collected through cookies and similar tracking technologies.
                            </li>
                          </ul>

                          <h2 className="text-xl font-bold mb-2">
                            How We Use Your Information
                          </h2>
                          <p className="mb-4">
                            We may use the information we collect for various purposes, including:
                          </p>
                          <ul className="list-disc pl-6 mb-4">
                            <li>To provide and maintain our Site;</li>
                            <li>To respond to your inquiries and communicate with you;</li>
                            <li>To analyze and improve the content and functionality of our Site;</li>
                            <li>To personalize your experience and deliver relevant content;</li>
                            <li>To prevent fraud and enhance the security of our Site;</li>
                            <li>To comply with legal obligations and enforce our policies.</li>
                          </ul>

                          <p className="text-sm text-gray-500">
                            Last updated: 5/15/2024 
                          </p>
                        </div>
                        <button
                          onClick={toggleModal}
                          className="absolute top-0 right-0 m-3 text-gray-400 hover:text-gray-500"
                          type="button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M3.293 4.293a1 1 0 011.414 0L10 8.586l5.293-5.293a1 1 0 111.414 1.414L11.414 10l5.293 5.293a1 1 0 01-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 01-1.414-1.414L8.586 10 3.293 4.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="flex flex-col items-center space-y-2 md:flex-row md:justify-between md:space-y-0">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            © 2024{" "}
            <a href="/" className="hover:underline">
              Angel Haven Estates
            </a>
            . All Rights Reserved.
          </span>
          <span className="text-sm text-gray-500 dark:text-gray-400 md:text-right">
            Like this website? Contact{" "}
            <a href="mailto:admin@devin-kim.com" className="font-semibold">
              admin@devin-kim.com
            </a>{" "}
          </span>
        </div>
      </div>
    </footer>
  );
}

