import React from "react";
import { Helmet } from "react-helmet";
import PhotoUpload from "../components/Admin/PhotoUpload.js";
import AvailabilityUpdater from "../components/Admin/Availability.js";

export default function Admin() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AH | Admin</title>
        <link rel="canonical" href="https://angelhavenbythecreek.com/admin" />
        <meta
          name="description"
          content="Administrator"
        />
      </Helmet>
      <AvailabilityUpdater/>
      <PhotoUpload />
    </div>
  );
}
