/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAvailability = /* GraphQL */ `
  mutation CreateAvailability(
    $input: CreateAvailabilityInput!
    $condition: ModelAvailabilityConditionInput
  ) {
    createAvailability(input: $input, condition: $condition) {
      id
      availableBeds
      updatedBy
      updateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAvailability = /* GraphQL */ `
  mutation UpdateAvailability(
    $input: UpdateAvailabilityInput!
    $condition: ModelAvailabilityConditionInput
  ) {
    updateAvailability(input: $input, condition: $condition) {
      id
      availableBeds
      updatedBy
      updateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAvailability = /* GraphQL */ `
  mutation DeleteAvailability(
    $input: DeleteAvailabilityInput!
    $condition: ModelAvailabilityConditionInput
  ) {
    deleteAvailability(input: $input, condition: $condition) {
      id
      availableBeds
      updatedBy
      updateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPhotos = /* GraphQL */ `
  mutation CreatePhotos(
    $input: CreatePhotosInput!
    $condition: ModelPhotosConditionInput
  ) {
    createPhotos(input: $input, condition: $condition) {
      id
      name
      sectionName
      sectionOrder
      sectionDescription
      photoName
      photoPath
      updatedBy
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updatePhotos = /* GraphQL */ `
  mutation UpdatePhotos(
    $input: UpdatePhotosInput!
    $condition: ModelPhotosConditionInput
  ) {
    updatePhotos(input: $input, condition: $condition) {
      id
      name
      sectionName
      sectionOrder
      sectionDescription
      photoName
      photoPath
      updatedBy
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const deletePhotos = /* GraphQL */ `
  mutation DeletePhotos(
    $input: DeletePhotosInput!
    $condition: ModelPhotosConditionInput
  ) {
    deletePhotos(input: $input, condition: $condition) {
      id
      name
      sectionName
      sectionOrder
      sectionDescription
      photoName
      photoPath
      updatedBy
      updatedAt
      createdAt
      __typename
    }
  }
`;
