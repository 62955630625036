import React, { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import { createAvailability, updateAvailability } from '../../graphql/mutations';
import { listAvailabilities } from '../../graphql/queries';

const client = generateClient();

const AvailabilityUpdater = () => {
  const [availabilityRecord, setAvailabilityRecord] = useState(null);
  const [availableBeds, setAvailableBeds] = useState('');
  const [updatedBy, setUpdatedBy] = useState('');
  const [updateTime, setUpdateTime] = useState('');
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  useEffect(() => {
    fetchAvailabilityRecord();
    fetchCurrentUser();
  }, []);

  const fetchAvailabilityRecord = async () => {
    try {
      const result = await client.graphql({
        query: listAvailabilities,
      });
      const records = result.data.listAvailabilities.items;
      if (records.length > 0) {
        const record = records[0];
        setAvailabilityRecord(record);
        setAvailableBeds(record.availableBeds);
        setUpdatedBy(record.updatedBy);
        setUpdateTime(record.updateTime);
      }
    } catch (error) {
      console.error("Error fetching availability record:", error.errors[0].message);
      setError(error.errors[0].message);
    }
  };

  const fetchCurrentUser = async () => {
    try {
      const userAttributes = await fetchUserAttributes();
      console.log(userAttributes);

      const user = await getCurrentUser();
      setUpdatedBy(user.signInDetails.loginId);
    } catch (error) {
      console.error("Error fetching current user:", error.errors[0].message);
      setError(error.errors[0].message);
    }
  };

  const resetSession = () => {
    sessionStorage.removeItem('availability');
  };

  const handleSave = async () => {
    setIsSaving(true);
    setSaveSuccess(false);
    try {
      await fetchCurrentUser(); // Ensure the updated username is captured before saving
      const newUpdateTime = new Date().toISOString();
      if (availabilityRecord) {
        const result = await client.graphql({
          query: updateAvailability,
          variables: {
            input: {
              id: availabilityRecord.id,
              availableBeds: parseInt(availableBeds, 10),
              updatedBy,
              updateTime: newUpdateTime,
            },
          },
        });
        console.log("Updated availability record:", result.data.updateAvailability);
        setAvailabilityRecord(result.data.updateAvailability);
        setUpdateTime(newUpdateTime);
      } else {
        const result = await client.graphql({
          query: createAvailability,
          variables: {
            input: {
              availableBeds: parseInt(availableBeds, 10),
              updatedBy,
              updateTime: newUpdateTime,
            },
          },
        });
        console.log("Created availability record:", result.data.createAvailability);
        setAvailabilityRecord(result.data.createAvailability);
        setUpdateTime(newUpdateTime);
      }
      resetSession(); // Reset session storage after updating availability
      setError(''); // Clear any previous errors
      setSaveSuccess(true); // Indicate save success
    } catch (error) {
      console.error("Error saving availability record:", error);
      setError("Error saving availability record");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Availability Updater</h1>
      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-2">Availability Record</h2>
        <div className="mb-4">
          <label className="block text-gray-700">Available Beds</label>
          <input
            type="number"
            value={availableBeds}
            onChange={(e) => setAvailableBeds(e.target.value)}
            placeholder="Available Beds"
            className="border border-gray-300 p-2 rounded-md w-full"
          />
        </div>
        {updatedBy && updateTime && (
          <div className="mb-4 text-gray-700">
            Last modified by {updatedBy} at {new Date(updateTime).toLocaleString()}
          </div>
        )}
        <div className="flex items-center">
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center"
            disabled={isSaving}
          >
            {isSaving && (
              <svg
                className="animate-spin h-5 w-5 mr-3 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                ></path>
              </svg>
            )}
            Save
          </button>
          {saveSuccess && (
            <svg
              className="h-6 w-6 text-green-500 ml-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
          )}
        </div>
        {error && (
          <div className="mt-4 p-2 bg-red-100 text-red-700 border border-red-400 rounded">
            {error}
          </div>
        )}
      </div>
      <hr className="mt-8 border-t-2 border-gray-300" />
    </div>
  );
};

export default AvailabilityUpdater;