import React from "react";
import Login from "../components/Login/Login.js"
import { Helmet } from "react-helmet";

export default function Amenities() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AH | Login</title>
        <link rel="canonical" href="https://angelhavenbythecreek.com/login" />
        <meta
          name="description"
          content="Login for angel haven users"
        />
      </Helmet>
      <Login />
    </div>
  );
}
