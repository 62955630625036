/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAvailability = /* GraphQL */ `
  query GetAvailability($id: ID!) {
    getAvailability(id: $id) {
      id
      availableBeds
      updatedBy
      updateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAvailabilities = /* GraphQL */ `
  query ListAvailabilities(
    $filter: ModelAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAvailabilities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        availableBeds
        updatedBy
        updateTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPhotos = /* GraphQL */ `
  query GetPhotos($id: ID!) {
    getPhotos(id: $id) {
      id
      name
      sectionName
      sectionOrder
      sectionDescription
      photoName
      photoPath
      updatedBy
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $filter: ModelPhotosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        sectionName
        sectionOrder
        sectionDescription
        photoName
        photoPath
        updatedBy
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
