import { hydrate, render } from "react-dom";
import React from "react";
import App from "./App";
import "./index.css";
import { Authenticator } from '@aws-amplify/ui-react';

const rootElement = document.getElementById("root");

const WrappedApp = () => (
  <Authenticator.Provider>
    <App />
  </Authenticator.Provider>
);

if (rootElement.hasChildNodes()) {
  hydrate(<WrappedApp />, rootElement);
} else {
  render(<WrappedApp />, rootElement);
}