import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuthenticator } from '@aws-amplify/ui-react';
import logo from "../assets/logo.png";

export default function NavigationBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate(); // Hook to navigate programmatically
  const location = useLocation(); // Hook to get the current location

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate("/login"); // Redirect to login page after logout
    } catch (err) {
      console.error("Failed to log out", err);
    }
  };

  const getLinkClass = (path) => {
    return location.pathname === path
      ? "block py-2 px-3 text-gray-900 font-bold text-lg rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-800 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-800 dark:hover:text-white md:dark:hover:bg-transparent"
      : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-800 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-800 dark:hover:text-white md:dark:hover:bg-transparent";
  };

  return (
    <nav className="bg-blue-50 dark:bg-gray-900">
      <div className="max-w-screen-xl mx-auto p-4 flex flex-col items-center md:flex-row md:justify-between">
        <div className="flex flex-col items-center md:flex-row">
          <a href="/" className="mb-4 md:mb-0">
            <img src={logo} className="h-20" alt="Logo" />
          </a>
          <div className="flex justify-center w-full md:hidden">
            <button
              onClick={handleMenuToggle}
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-gray-600"
              aria-controls="navbar-default"
              aria-expanded={isMenuOpen ? "true" : "false"}
            >
              <span className="sr-only">Open main menu</span>
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </button>
          </div>
        </div>
        <div className={`${isMenuOpen ? "block" : "hidden"} w-full md:block md:w-auto`} id="navbar-default">
          <ul className="flex flex-col md:flex-row md:space-x-8 mt-4 md:mt-0 md:text-sm md:font-medium">
            <li>
              <Link
                to="/"
                className={getLinkClass("/")}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={getLinkClass("/about")}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                className={getLinkClass("/services")}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/amenities"
                className={getLinkClass("/amenities")}
              >
                Amenities
              </Link>
            </li>
            <li>
              <Link
                to="/cost"
                className={getLinkClass("/cost")}
              >
                Cost
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={getLinkClass("/contact")}
              >
                Contact
              </Link>
            </li>
            {user && (
              <li>
                <Link
                  to="/admin"
                  className={getLinkClass("/admin")}
                >
                  Admin
                </Link>
              </li>
            )}
            {user ? (
              <li>
                <button
                  onClick={handleLogout}
                  className="block py-2 px-3 text-red-500 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-red-700 md:p-0 dark:text-red-500 dark:hover:bg-gray-800 dark:hover:text-red-700 md:dark:hover:bg-transparent"
                >
                  Logout
                </button>
              </li>
            ) : (
              <li>
                <Link
                  to="/login"
                  className={getLinkClass("/login")}
                >
                  Login
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}