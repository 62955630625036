import React, { useState, useEffect } from "react";
import { generateClient } from "@aws-amplify/api";
import { listPhotos } from "../../graphql/queries";
import * as Storage from "@aws-amplify/storage";

const Gallery = () => {
  const [photosBySection, setPhotosBySection] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null); // State for selected image
  const [error, setError] = useState(null);

  const client = generateClient({
    authMode: "apiKey",
  });

  useEffect(() => {
    fetchPhotos();
  }, []);

  const fetchPhotos = async () => {
    try {
      const photoData = await client.graphql({ query: listPhotos });
      const photos = photoData.data.listPhotos.items;

      const groupedPhotos = photos.reduce((acc, photo) => {
        if (!acc[photo.sectionName]) {
          acc[photo.sectionName] = [];
        }
        acc[photo.sectionName].push(photo);
        return acc;
      }, {});

      // Fetch URLs for each photo
      const fetchUrlsPromises = Object.keys(groupedPhotos).map(async (section) => {
        const photosWithUrls = await Promise.all(groupedPhotos[section].map(async (photo) => {
          const getUrlResult = await Storage.getUrl({
            path: photo.photoPath,
            options: {
              validateObjectExistence: true,
              expiresIn: 900,
            },
          });
          return { ...photo, url: getUrlResult.url.href };
        }));
        return { section, photos: photosWithUrls };
      });

      const sectionsWithUrls = await Promise.all(fetchUrlsPromises);
      const photosBySectionWithUrls = sectionsWithUrls.reduce((acc, { section, photos }) => {
        acc[section] = photos;
        return acc;
      }, {});

      // Sort sections by sectionOrder before setting state
      const sortedSections = Object.keys(photosBySectionWithUrls)
        .sort((a, b) => photosBySectionWithUrls[a][0].sectionOrder - photosBySectionWithUrls[b][0].sectionOrder)
        .reduce((acc, section) => {
          acc[section] = photosBySectionWithUrls[section];
          return acc;
        }, {});

      setPhotosBySection(sortedSections);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching photos:", error);
      setError(error);
      setLoading(false);
    }
  };

  // Handle image click to open in modal
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl); // Set the selected image URL
  };

  // Handle close modal
  const handleCloseModal = () => {
    setSelectedImage(null); // Clear the selected image URL
  };

  return (
    <div className="container mx-auto p-4 max-w-full">
      {error && <p className="text-red-500">Error fetching photos. Please try again later.</p>}
      {loading ? (
        <p>Loading photos...</p>
      ) : (
        Object.keys(photosBySection)
          .sort((a, b) => photosBySection[a][0].sectionOrder - photosBySection[b][0].sectionOrder)
          .map((section) => (
            <div key={section} className="mb-8 p-4">
              <div className="text-center">
              <h2 className="text-center text-5xl leading-[58px] text-zinc-800 font-bold max-md:max-w-full max-md:text-4xl max-md:leading-[54px]"> {section}</h2>             
              

                <p className="text-center text-gray-500 dark:text-gray-400 mb-12">
                  {photosBySection[section][0].sectionDescription}
                </p>

              </div>
              <div className="flex flex-wrap -mx-2">
                {photosBySection[section].map((photo) => (
                  <div key={photo.id} className="w-1/3 p-2">
                    <img
                      src={photo.url}
                      alt={photo.photoName}
                      className="mb-4 w-full h-48 object-cover cursor-pointer"
                      onClick={() => handleImageClick(photo.url)}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))
      )}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center" onClick={handleCloseModal}>
          <img src={selectedImage} alt="Selected" className="max-w-90 max-h-90" />
          <button onClick={handleCloseModal} className="absolute top-4 right-4 bg-white p-2 rounded">Close</button>
        </div>
      )}
    </div>
  );
};

export default Gallery;