import React, { useState, useEffect } from "react";
import * as Storage from "@aws-amplify/storage";
import { uploadData } from "@aws-amplify/storage";
import { generateClient } from "@aws-amplify/api";
import { listPhotos } from "../../graphql/queries";
import { createPhotos, deletePhotos, updatePhotos } from "../../graphql/mutations";
import { getCurrentUser } from '@aws-amplify/auth';
import { FaEdit, FaArrowUp, FaArrowDown, FaTrash } from 'react-icons/fa';
import Resizer from 'react-image-file-resizer';

const PhotoUploader = () => {
  const [files, setFiles] = useState([]);
  const [expandedPhotoUrl, setExpandedPhotoUrl] = useState(null);
  const [photosBySection, setPhotosBySection] = useState({});
  const [sectionName, setSectionName] = useState("");
  const [sectionDescription, setSectionDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [photosLoaded, setPhotosLoaded] = useState(false);
  const [editingSection, setEditingSection] = useState(null);
  const [editingDescription, setEditingDescription] = useState(null);
  const [savingSection, setSavingSection] = useState(null);
  const [existingSections, setExistingSections] = useState([]);
  const [isNewSection, setIsNewSection] = useState(true);

  const client = generateClient();

  useEffect(() => {
    fetchPhotos();
  }, []);

  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files);
    const resizedFilesPromises = filesArray.map(file => resizeFile(file));
    Promise.all(resizedFilesPromises).then(resizedFiles => {
      setFiles(resizedFiles);
    });
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800, // max width
        800, // max height
        "JPEG",
        100, // quality
        0, // rotation
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const fetchPhotos = async () => {
    try {
      const photoData = await client.graphql({ query: listPhotos });
      const photos = photoData.data.listPhotos.items;
      const groupedPhotos = photos.reduce((acc, photo) => {
        if (!acc[photo.sectionName]) {
          acc[photo.sectionName] = [];
        }
        acc[photo.sectionName].push(photo);
        return acc;
      }, {});
      setPhotosBySection(groupedPhotos);
      setExistingSections(Object.keys(groupedPhotos));
      setPhotosLoaded(true);
    } catch (err) {
      console.error("Error fetching photos:", err);
      setUploadError("Error fetching photos: " + err);
    }
  };

  const handleSectionChange = (e) => {
    const selectedSection = e.target.value;
    if (selectedSection === "new") {
      setIsNewSection(true);
      setSectionName("");
    } else {
      setIsNewSection(false);
      setSectionName(selectedSection);
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    setUploadError(null);
    try {
      const currentUser = await getCurrentUser();
      const username = currentUser.username;

      const uploadPromises = files.map(async (file) => {
        const s3Path = `public/${file.name}`;
        const result = await uploadData({ path: s3Path, data: file }).result;
        console.log("Uploaded file:", result);
        
        const photoData = {
          name: file.name,
          sectionName,
          sectionOrder: Object.keys(photosBySection).length + 1,
          sectionDescription,
          photoName: `public/${file.name}`,
          photoPath: s3Path,
          updatedBy: username,
          updatedAt: new Date().toISOString(),
        };
        await client.graphql({
          query: createPhotos,
          variables: { input: photoData },
        });
      });
      await Promise.all(uploadPromises);
      sessionStorage.removeItem('photosBySection'); // Clear session storage
      fetchPhotos();
      setFiles([]);
      setSectionName("");
      setSectionDescription("");
    } catch (err) {
      console.error("Error uploading files:", err);
      setUploadError("Error uploading files");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (photo) => {
    setDeleteError(null);
    try {
      await client.graphql({
        query: deletePhotos,
        variables: { input: { id: photo.id } },
      });
      const photoPath = photo.photoPath;
      await Storage.remove({path: photoPath});
      sessionStorage.removeItem('photosBySection'); // Clear session storage
      fetchPhotos();
    } catch (err) {
      console.error("Error deleting photo:", err);
      setDeleteError("Error deleting photo");
    }
  };

  const handleDeleteSection = async (section) => {
    setDeleteError(null);
    try {
      const deletePromises = photosBySection[section].map(async (photo) => {
        await client.graphql({
          query: deletePhotos,
          variables: { input: { id: photo.id } },
        });
        await Storage.remove({path: photo.photoPath});
      });
      await Promise.all(deletePromises);

      const newPhotosBySection = { ...photosBySection };
      delete newPhotosBySection[section];
      setPhotosBySection(newPhotosBySection);

      // Update the order in the backend
      const sectionKeys = Object.keys(newPhotosBySection);
      await Promise.all(sectionKeys.map(async (key, i) => {
        const updatePromises = newPhotosBySection[key].map(async (photo) => {
          await client.graphql({
            query: updatePhotos,
            variables: {
              input: {
                id: photo.id,
                sectionOrder: i + 1,
              },
            },
          });
        });
        await Promise.all(updatePromises);
      }));

      sessionStorage.removeItem('photosBySection'); // Clear session storage
      fetchPhotos();
    } catch (err) {
      console.error("Error deleting section:", err);
      setDeleteError("Error deleting section");
    }
  };

  const handlePhotoClick = (url) => {
    setExpandedPhotoUrl(url);
  };

  const handleCloseModal = () => {
    setExpandedPhotoUrl(null);
  };

  const handleSectionNameChange = (e, section) => {
    const updatedSections = { ...photosBySection };
    updatedSections[section][0].sectionName = e.target.value;
    setPhotosBySection(updatedSections);
  };

  const handleSectionDescriptionChange = (e, section) => {
    const updatedSections = { ...photosBySection };
    updatedSections[section][0].sectionDescription = e.target.value;
    setPhotosBySection(updatedSections);
  };

  const updateSection = async (section) => {
    setUpdateError(null);
    setSavingSection(section);
    try {
      const sectionData = photosBySection[section][0];
      const updatePromises = photosBySection[section].map(async (photo) => {
        await client.graphql({
          query: updatePhotos,
          variables: {
            input: {
              id: photo.id,
              sectionName: sectionData.sectionName,
              sectionDescription: sectionData.sectionDescription,
              sectionOrder: sectionData.sectionOrder,
            },
          },
        });
      });
      await Promise.all(updatePromises);
      sessionStorage.removeItem('photosBySection'); // Clear session storage
      fetchPhotos();
      setEditingSection(null);
      setEditingDescription(null);
    } catch (err) {
      console.error("Error updating section:", err);
      setUpdateError("Error updating section");
    } finally {
      setSavingSection(null);
    }
  };

  const moveSection = async (section, direction) => {
    const sectionKeys = Object.keys(photosBySection);
    const index = sectionKeys.indexOf(section);
  
    if (index === -1) return;
  
    const isUp = direction === "up" && index > 0;
    const isDown = direction === "down" && index < sectionKeys.length - 1;
  
    if (!isUp && !isDown) return;
  
    const newIndex = isUp ? index - 1 : index + 1;
  
    // Reorder keys
    const newSectionKeys = [...sectionKeys];
    [newSectionKeys[index], newSectionKeys[newIndex]] = [newSectionKeys[newIndex], newSectionKeys[index]];
  
    // Update photosBySection with the new order
    const newPhotosBySection = {};
    newSectionKeys.forEach((key, i) => {
      newPhotosBySection[key] = photosBySection[key];
      newPhotosBySection[key].forEach((photo) => {
        photo.sectionOrder = i + 1;
      });
    });
  
    setPhotosBySection(newPhotosBySection);
  
    try {
      // Persist new order to the database
      await Promise.all(
        newSectionKeys.map(async (key) => {
          const updatePromises = newPhotosBySection[key].map(async (photo) => {
            await client.graphql({
              query: updatePhotos,
              variables: {
                input: {
                  id: photo.id,
                  sectionOrder: photo.sectionOrder,
                },
              },
            });
          });
          await Promise.all(updatePromises);
        })
      );
  
      sessionStorage.removeItem("photosBySection");
    } catch (err) {
      console.error("Error updating section order:", err);
      setUpdateError("Error updating section order");
    }
  };
  

  const handleClickOutside = (e, section) => {
    if (editingSection === section || editingDescription === section) {
      setEditingSection(null);
      setEditingDescription(null);
    }
  };

  return (
    <div className="container my-20 mx-auto p-4" onClick={(e) => handleClickOutside(e, null)}>
      <h1 className="text-2xl font-bold mb-4">Photo Uploader</h1>
      <div className="mb-4">
        <input type="file" multiple onChange={handleFileChange} className="mb-4 p-2 border rounded w-full" />
      </div>
      <div className="mb-4 ">
        <select onChange={handleSectionChange} className="mb-4 p-2 border rounded w-full">
          <option value="new">Create New Section</option>
          {existingSections.map((section) => (
            <option key={section} value={section}>
              {section} {photosBySection[section] && photosBySection[section].length > 0 ? `(Order: ${photosBySection[section][0].sectionOrder})` : ''}
            </option>

          ))}
        </select>
      </div>
      {isNewSection && (
        <div className="mb-4">
          <input
            type="text"
            placeholder="Section Name"
            value={sectionName}
            onChange={(e) => setSectionName(e.target.value)}
            className="mb-4 p-2 border rounded w-full"
          />
        </div>
      )}
      <div className="mb-4">
        <textarea
          placeholder="Section Description"
          value={sectionDescription}
          onChange={(e) => setSectionDescription(e.target.value)}
          className="mb-4 p-2 border rounded w-full"
        />
      </div>
      <div className="mb-4 ml-auto w-1/4">
        <button onClick={handleUpload} disabled={loading} className="bg-blue-500 text-white p-2 rounded w-full">
          {loading ? "Uploading..." : "Upload"}
        </button>
        {uploadError && (
          <div className="mt-2 p-2 border border-red-500 bg-red-100 text-red-700 rounded">
            {uploadError}
          </div>
        )}
      </div>
      <div className="mt-8">
        {photosLoaded ? (
          Object.keys(photosBySection)
            .sort((a, b) => photosBySection[a][0].sectionOrder - photosBySection[b][0].sectionOrder)
            .map((section) => (
              <div key={section} className="mb-8 p-4 border rounded-lg" onClick={(e) => handleClickOutside(e, section)}>
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center">
                    <button onClick={() => moveSection(section, 'up')} className="mr-2 p-2 bg-gray-200 rounded">
                      <FaArrowUp />
                    </button>
                    <button onClick={() => moveSection(section, 'down')} className="p-2 bg-gray-200 rounded">
                      <FaArrowDown />
                    </button>
                  </div>
                  <button onClick={() => updateSection(section)} className="p-2 bg-blue-500 text-white rounded" disabled={savingSection === section}>
                    {savingSection === section ? (
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                      </svg>
                    ) : (
                      "Save"
                    )}
                  </button>
                  <button onClick={() => handleDeleteSection(section)} className="p-2 bg-red-500 text-white rounded">
                    <FaTrash />
                  </button>
                  {updateError && (
                    <div className="mt-2 p-2 border border-red-500 bg-red-100 text-red-700 rounded">
                      {updateError}
                    </div>
                  )}
                </div>
                <div className="text-center">
                  {editingSection === section ? (
                    <div className="relative flex items-center justify-center">
                      <input
                        type="text"
                        value={photosBySection[section][0].sectionName}
                        onChange={(e) => handleSectionNameChange(e, section)}
                        className="border p-2 rounded text-center"
                        style={{ width: `${photosBySection[section][0].sectionName.length + 2}ch` }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>
                  ) : (
                    <h2
                      className="text-xl font-bold cursor-pointer flex items-center justify-center"
                      onClick={(e) => { e.stopPropagation(); setEditingSection(section); }}
                    >
                      {photosBySection[section][0].sectionName}
                      <FaEdit className="ml-2 text-gray-500" />
                    </h2>
                  )}
                </div>
                <div className="text-center">
                  {editingDescription === section ? (
                    <div className="relative flex items-center justify-center">
                      <textarea
                        value={photosBySection[section][0].sectionDescription}
                        onChange={(e) => handleSectionDescriptionChange(e, section)}
                        className="border p-2 rounded text-center"
                        style={{ width: `${photosBySection[section][0].sectionDescription.length + 2}ch` }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>
                  ) : (
                    <p
                      className="cursor-pointer flex items-center justify-center"
                      onClick={(e) => { e.stopPropagation(); setEditingDescription(section); }}
                    >
                      {photosBySection[section][0].sectionDescription}
                      <FaEdit className="ml-2 text-gray-500" />
                    </p>
                  )}
                </div>
                <div className="flex flex-wrap -mx-2">
                  {photosBySection[section].map((photo) => (
                    <div key={photo.key} className="w-1/3 p-2">
                      <PhotoItem photo={photo} setError={setDeleteError} handleDelete={handleDelete} handlePhotoClick={handlePhotoClick} />
                    </div>
                  ))}
                </div>
                {deleteError && (
                  <div className="mt-2 p-2 border border-red-500 bg-red-100 text-red-700 rounded">
                    {deleteError}
                  </div>
                )}
              </div>
            ))
        ) : (
          <p>Loading photos...</p>
        )}
      </div>
      {expandedPhotoUrl && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center" onClick={handleCloseModal}>
          <img src={expandedPhotoUrl} alt="Expanded" className="max-w-90 max-h-90" />
          <button onClick={handleCloseModal} className="absolute top-4 right-4 bg-white p-2 rounded">Close</button>
        </div>
      )}
    </div>
  );
};

const PhotoItem = ({ photo, setError, handleDelete, handlePhotoClick }) => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const fetchUrl = async () => {
      try {
        const photoPath = photo.photoPath;
        const getUrlResult = await Storage.getUrl({
          path: photoPath,
          options: {
            validateObjectExistence: true,
            expiresIn: 900,
          },
        });
        setUrl(getUrlResult.url.href);
      } catch (error) {
        console.error("Error downloading photo:", photo.photoPath, error);
        setError(`Error downloading photo: ${photo.photoPath}`);
      }
    };
    fetchUrl();
  }, [photo.photoPath, setError]);

  return (
    <div className="border p-4 rounded text-center">
      {url && (
        <img
          src={url}
          alt={photo.photoName}
          className="mb-4 w-full h-48 object-cover cursor-pointer"
          onClick={() => handlePhotoClick(url)}
        />
      )}
      <p className="font-bold">{photo.photoName}</p>
      <button
        onClick={() => handleDelete(photo)}
        className="bg-red-500 text-white p-2 rounded mt-2"
      >
        Delete
      </button>
    </div>
  );
};

export default PhotoUploader;